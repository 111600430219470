import { camelCaseToSnakeCase, snakeCaseToCamelCase } from "Utils/Casing";
import Cookies from "js-cookie";

interface Headers {
  [key: string]: string;
}

const call = (method: string) => {
  return async ({
    body,
    params,
    url,
  }: {
    body?: any;
    params?: any;
    url: string;
  }): Promise<any> => {
    const paramsSafe = camelCaseToSnakeCase(params) || {};
    const accessToken = Cookies.get("access_token");

    const defaultHeaders: Headers = {
      "Content-Type": "application/json",
      "Access-Token": accessToken || "",
    };

    const completeUrl = new URL(`${process.env.REACT_APP_BACKEND_URL}/${url}`);
    Object.keys(paramsSafe).forEach((key) =>
      completeUrl.searchParams.append(key, paramsSafe[key])
    );

    let responseContent;
    let isResponseOk = false;

    try {
      const response = await fetch(completeUrl.toString(), {
        body: JSON.stringify(body),
        headers: defaultHeaders,
        method,
      });

      isResponseOk = response.ok;
      responseContent = await response.json();
    } catch (error) {
      Promise.reject({ errorDescription: `Error calling ${url}` });
    }

    if (!isResponseOk) {
      return Promise.reject({
        errorDescription: responseContent,
      });
    }

    return Promise.resolve(snakeCaseToCamelCase(responseContent));
  };
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  delete: call("DELETE"),
  get: call("GET"),
  patch: call("PATCH"),
  post: call("POST"),
  put: call("PUT"),
};
