import { Carrier, CreateCarrierRequest } from "DataInterfaces/Carrier";
import { useHttpGet } from "NetworkInterfaces/Core/HttpGet";
import {
  useMutation,
  useMutationOptions,
  useMutationReturn,
} from "NetworkInterfaces/Core/useMutation";
import client from "NetworkInterfaces/Core/ApiClient";

export const CARRIERS_CACHE_KEY = "carriers_cache_key";

export const useGetAllCarriers = (): {
  carriers: Carrier[] | undefined;
  isLoadingCarriers: boolean;
} => {
  const { data: carriers, isLoading: isLoadingCarriers } = useHttpGet(
    [CARRIERS_CACHE_KEY],
    "carriers"
  );

  return {
    carriers: carriers,
    isLoadingCarriers: isLoadingCarriers,
  };
};

export const useCreateCarrier = (
  options?: useMutationOptions<CreateCarrierRequest>
): useMutationReturn<CreateCarrierRequest> => {
  return useMutation(
    (payload) =>
      client.post({
        body: payload,
        url: `carriers`,
      }),
    {
      invalidations: [
        {
          key: [CARRIERS_CACHE_KEY],
        },
      ],
      ...options,
    }
  );
};
