export const camelCaseToSnakeCase = (object: any) => {
  /*
      This function converts camelCase to snake_case recursively.
      It also removes undefined values.
    */
  if (typeof object != "object") return object;

  for (var oldName in object) {
    // Camel to snake case
    const newName = oldName.replace(/([A-Z])/g, function ($1) {
      return "_" + $1.toLowerCase();
    });

    // Only process if names are different
    if (!(newName === oldName)) {
      // Check for the old property name to avoid a ReferenceError in strict mode.
      if (object.hasOwnProperty(oldName)) {
        object[newName] = object[oldName];
        delete object[oldName];
      }
    }

    // Recursion
    if (typeof object[newName] == "object") {
      object[newName] = camelCaseToSnakeCase(object[newName]);
    }
  }
  return object;
};

export const snakeCaseToCamelCase = (object: any) => {
  /*
    This function converts snake_case to camelCase recursively.
    It also removes undefined values.
  */
  if (typeof object != "object") return object;

  for (var oldName in object) {
    // Snake to camel case
    const newName = oldName.replace(/(_\w)/g, function (match) {
      return match[1].toUpperCase();
    });

    // Only process if names are different
    if (!(newName === oldName)) {
      // Check for the old property name to avoid a ReferenceError in strict mode.
      if (object.hasOwnProperty(oldName)) {
        object[newName] = object[oldName];
        delete object[oldName];
      }
    }

    // Recursion
    if (typeof object[newName] == "object") {
      object[newName] = snakeCaseToCamelCase(object[newName]);
    }
  }
  return object;
};
