import { DownOutlined, EditOutlined } from "@ant-design/icons";
import { useGetAllCarriers } from "NetworkInterfaces/Carriers";
import { OrdersTabContent } from "UI/CarrierDetailsSection/OrdersTabContent";
import { Button, Dropdown, Flex, Space, Spin, Tabs, Typography } from "antd";
import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";

const { Text, Title } = Typography;

enum CarrierActionsMenuOption {
  Edit = "Edit",
}

enum CarrierTabsKeys {
  Orders = "orders",
  Dispatches = "oispatches",
  Vehicles = "vehicles",
}

const CARRIER_TAB_PROPS_BY_CARRIER_TAB_KEY: {
  [key in CarrierTabsKeys]: {
    label: string;
    tabContent: JSX.Element;
  };
} = {
  [CarrierTabsKeys.Orders]: {
    label: "Orders",
    tabContent: <OrdersTabContent />,
  },
  [CarrierTabsKeys.Dispatches]: {
    label: "Dispatches",
    tabContent: <Text type={"secondary"}>No content</Text>,
  },
  [CarrierTabsKeys.Vehicles]: {
    label: "Vehicles",
    tabContent: <Text type={"secondary"}>No content</Text>,
  },
};

export const CarrierDetailsSection: FunctionComponent = () => {
  const { carrierId } = useParams();

  const { carriers, isLoadingCarriers } = useGetAllCarriers();

  const carrier = carriers?.find((carrier) => carrier.id === carrierId);

  return (
    <Spin spinning={isLoadingCarriers}>
      <Flex justify={"space-between"} align={"center"}>
        <Title level={1}>{carrier?.name}</Title>
        <Dropdown
          menu={{
            items: [
              {
                key: CarrierActionsMenuOption.Edit,
                icon: <EditOutlined />,
                label: CarrierActionsMenuOption.Edit,
                onClick: () => {
                  console.log("edit");
                },
              },
            ],
          }}
        >
          <Button>
            <Space>
              Actions
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </Flex>
      <Tabs
        defaultActiveKey={CarrierTabsKeys.Orders}
        items={Object.values(CarrierTabsKeys).map((carrierTabKey) => {
          const tabProps =
            CARRIER_TAB_PROPS_BY_CARRIER_TAB_KEY[
              carrierTabKey as CarrierTabsKeys
            ];

          return {
            key: carrierTabKey,
            label: tabProps.label,
            children: tabProps.tabContent,
          };
        })}
      />
    </Spin>
  );
};
