export const getCarrierIdentifierFromJavisIdentifier = (
  javisIdentifier: string
): string => {
  const indexOfDash = javisIdentifier.indexOf("-");

  if (indexOfDash !== -1) {
    return javisIdentifier.substring(indexOfDash + 1);
  }

  return javisIdentifier;
};
