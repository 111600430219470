import { ScopedMutator } from "swr/_internal";
import { Key, useSWRConfig } from "swr";

interface useMatchMutateReturn {
  matchMutate: ScopedMutator;
}

export const useMatchMutate = (): useMatchMutateReturn => {
  const { cache, mutate } = useSWRConfig();

  return {
    matchMutate: (key: Key, ...rest: any) => {
      if (!(cache instanceof Map)) {
        throw new Error("matchMutate requires the cache to be a Map");
      }

      const serializedKey =
        key instanceof Array
          ? key
              .map((subkey, index) => {
                if (!subkey) {
                  return "undefined";
                }
                if (index === 0) {
                  return `@"${subkey}"`;
                } else {
                  return `"${subkey}"`;
                }
              })
              .join(",")
          : !!key
          ? String(key)
          : undefined;

      if (!serializedKey) {
        return Promise.all([]);
      }

      const infiniteSerializedKeyRegex = `^(\\$inf\\$)?${serializedKey}`;

      const matcher = new RegExp(infiniteSerializedKeyRegex);
      const keysToInvalidate = [];
      for (const cacheKey of cache.keys()) {
        if (matcher.test(cacheKey)) {
          keysToInvalidate.push(cacheKey);
        }
      }

      const mutations = keysToInvalidate.map((keyToInvalidate) =>
        mutate(keyToInvalidate, ...rest)
      );

      return Promise.all(mutations);
    },
  };
};
