import { Load } from "DataInterfaces/Common/Loads";

export const getMetersFromMillimeters = (millimeters: number): number => {
  return millimeters / 1000;
};

export const getKilosFromGrams = (grams: number): number => {
  return grams / 1000;
};

export const getLoadsTotalVolumeM3 = (loads: Load[]): number | undefined => {
  if (
    loads.some(
      (load) =>
        load.height === undefined ||
        load.height === null ||
        load.length === undefined ||
        load.length === null ||
        load.width === undefined ||
        load.width === null
    )
  )
    return undefined;

  return loads.reduce((total, load) => {
    if (
      load.height === undefined ||
      load.height === null ||
      load.length === undefined ||
      load.length === null ||
      load.width === undefined ||
      load.width === null
    ) {
      throw new Error("This should never happen");
    }
    return (
      total +
      getMetersFromMillimeters(load.width) *
        getMetersFromMillimeters(load.height) *
        getMetersFromMillimeters(load.length)
    );
  }, 0);
};

export const getLoadsTotalWeightKg = (loads: Load[]): number | undefined => {
  if (loads.some((load) => load.weight === undefined || load.weight === null))
    return undefined;
  return loads.reduce((total, load) => {
    if (load.weight === undefined || load.weight === null) {
      throw new Error("This should never happen");
    }
    return total + getKilosFromGrams(load.weight);
  }, 0);
};
