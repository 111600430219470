import { createRoot } from "react-dom/client";
import { StrictMode } from "react";
import { App } from "App";
import { SWRConfig } from "swr/_internal";
import client from "NetworkInterfaces/Core/ApiClient";

const fetcher = (url: string) => client.get({ url });

const domNode = document.getElementById("root");
if (domNode) {
  const root = createRoot(domNode);

  root.render(
    <SWRConfig
      value={{
        fetcher: fetcher,
      }}
    >
      <StrictMode>
        <App />
      </StrictMode>
    </SWRConfig>
  );
} else {
  console.error("Element with id 'root' not found in the document.");
}
