import { BaseLayout, baseLayoutLoader } from "Components/BaseLayout";
import { FunctionComponent } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  AuthenticationPage,
  authenticationPageLoader,
} from "UI/Authentication";
import { CarrierDetailsSection } from "UI/CarrierDetailsSection";
import { CarriersSection } from "UI/CarriersSection";

const router = createBrowserRouter([
  {
    path: "/",
    loader: baseLayoutLoader,
    element: <BaseLayout />,
    children: [
      {
        path: "/carriers",
        element: <CarriersSection />,
      },
      {
        path: "/carriers/:carrierId",
        element: <CarrierDetailsSection />,
      },
    ],
  },
  {
    path: "/auth",
    loader: authenticationPageLoader,
    Component: AuthenticationPage,
  },
]);

export const App: FunctionComponent = () => {
  return <RouterProvider router={router} />;
};
