import useSWR, { Key } from "swr";
import client from "NetworkInterfaces/Core/ApiClient";

export interface HttpGetOptions<Data = any, Error = any> {
  enabled?: boolean;
  onSuccess?: (data: Data) => void;
  onError?: (error: Error) => void;
}

export const useHttpGet: <Data = any, Error = any>(
  key: Key,
  endpoint: string,
  params?: { [key: string]: any },
  options?: HttpGetOptions<Data, Error>
) => {
  data: any;
  isLoading: boolean;
} = (key, endpoint, params, options) => {
  const { data, error } = useSWR(
    options?.enabled === undefined || options?.enabled ? key : null,
    async () => {
      return await client.get({ url: endpoint, params: params });
    },
    {
      revalidateOnFocus: false,
      ...options,
    }
  );

  return {
    data,
    isLoading: error === undefined && data === undefined,
  };
};
