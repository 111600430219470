import { PlusOutlined } from "@ant-design/icons";
import {
  useCreateCarrier,
  useGetAllCarriers,
} from "NetworkInterfaces/Carriers";
import {
  Button,
  Flex,
  Input,
  Popconfirm,
  Space,
  Table,
  Typography,
} from "antd";
import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";

const { Text, Title } = Typography;

const DEFAULT_NEW_CARRIER_NAME = "New Carrier";

export const CarriersSection: FunctionComponent = () => {
  const navigate = useNavigate();

  const { carriers, isLoadingCarriers } = useGetAllCarriers();
  const { mutate: createCarrier, isLoading: isLoadingCreateCarrier } =
    useCreateCarrier();

  const [nameFilter, setNameFilter] = useState<string>("");

  return (
    <div>
      <Flex justify={"space-between"} align={"center"}>
        <Title level={1}>Carriers </Title>
        <Popconfirm
          title={
            <Text>
              A new carrier with name "{DEFAULT_NEW_CARRIER_NAME}"
              <br />
              will be created.
              <br />
              You can edit it opening the carrier's page.
            </Text>
          }
          okText={"Create"}
          onConfirm={() =>
            createCarrier({
              name: DEFAULT_NEW_CARRIER_NAME,
            })
          }
        >
          <Button icon={<PlusOutlined />} loading={isLoadingCreateCarrier}>
            Add new carrier
          </Button>
        </Popconfirm>
      </Flex>
      <Space direction={"vertical"} style={{ width: "100%" }} size={16}>
        <div>
          <Title level={5}>Filter by</Title>
          <Input
            placeholder="Search by name"
            allowClear
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value.toLowerCase())}
          />
        </div>
        <Table
          columns={[
            {
              title: "Name",
              dataIndex: "name",
              key: "name",
              render: (text) => <Text>{text}</Text>,
            },
            {
              title: "Api Keys",
              dataIndex: "apiKeys",
              key: "apiKeys",
              render: (apiKeys) => apiKeys.length,
            },
          ]}
          dataSource={carriers
            ?.map((carrier) => ({
              ...carrier,
              key: carrier.id,
            }))
            .filter((carrier) =>
              carrier.name.toLowerCase().includes(nameFilter)
            )}
          loading={isLoadingCarriers}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                console.log(record, rowIndex);
                navigate("/carriers/" + record.id);
              },
            };
          }}
        />
      </Space>
    </div>
  );
};
