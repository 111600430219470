import { CloseOutlined } from "@ant-design/icons";
import { Load } from "DataInterfaces/Common/Loads";
import { PickupDeliveryInformation } from "DataInterfaces/Common/PickupDeliveryInformation";
import { Order } from "DataInterfaces/Order";
import { useGetOrdersPaginated } from "NetworkInterfaces/Orders";
import { getCarrierIdentifierFromJavisIdentifier } from "Utils/JavisIdentifierConverter";
import { getLoadsTotalVolumeM3, getLoadsTotalWeightKg } from "Utils/Loads";
import { formatTimestamp } from "Utils/Time";
import { Col, Popover, Row, Table, Tooltip, Typography } from "antd";
import { FunctionComponent, useState } from "react";
import { useParams } from "react-router-dom";

const { Text } = Typography;

const DEFAULT_PAGE = 1;
const DEFAULT_PER_PAGE = 10;

const renderFromToTime = (
  timeFrom?: number,
  timeTo?: number,
  isTimeFlexible: boolean = false
) => {
  return (
    <Tooltip title={isTimeFlexible ? "Time is flexible" : "Not flexible"}>
      {!!timeFrom && (
        <Row>
          <Col span={6}>
            <Text type={"secondary"}>From: </Text>
          </Col>
          <Text type={"secondary"}>{formatTimestamp(timeFrom)}</Text>
        </Row>
      )}

      {!!timeTo && (
        <Row>
          <Col span={6}>
            <Text type={"secondary"}>To: </Text>
          </Col>
          <Text type={"secondary"}>{formatTimestamp(timeTo)}</Text>
        </Row>
      )}
    </Tooltip>
  );
};

export const OrdersTabContent: FunctionComponent = () => {
  const { carrierId } = useParams();
  const isGetOrdersPaginatedEnabled = !!carrierId;

  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const [perPage, setPerPage] = useState<number>(DEFAULT_PER_PAGE);

  const { ordersPaginatedResponse, isLoadingOrdersPaginatedResponse } =
    useGetOrdersPaginated(
      {
        carrierId: carrierId || "",
        page: page,
        perPage: perPage,
      },
      isGetOrdersPaginatedEnabled
    );

  const ordersPaginated = ordersPaginatedResponse?.orders;
  const totalOrders = ordersPaginatedResponse?.totalOrders;

  return (
    <Table
      columns={[
        {
          title: "Order identifier",
          dataIndex: "orderIdentifier",
          key: "orderIdentifier",
          render: (orderIdentifier: string) => (
            <Text strong>
              {getCarrierIdentifierFromJavisIdentifier(orderIdentifier)}
            </Text>
          ),
          width: "15%",
          fixed: "left",
        },
        {
          title: "Reference identifier",
          dataIndex: "referenceIdentifier",
          key: "referenceIdentifier",
          render: (referenceIdentifier) => (
            <Text strong>{referenceIdentifier}</Text>
          ),
          width: "15%",
          fixed: "left",
        },
        {
          title: "Loads",
          dataIndex: "loads",
          key: "loads",
          render: (loads: Load[]) => {
            const loadsTotalVolume = getLoadsTotalVolumeM3(loads)?.toFixed(5);
            const loadsTotalWeight = getLoadsTotalWeightKg(loads)?.toFixed(5);
            return (
              <Popover
                title={
                  !!loads.length && (
                    <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                      {loads.map((load, index) => (
                        <>
                          Load #{index + 1}
                          <ul style={{ marginTop: 0 }}>
                            <li>Description: {load.description}</li>
                            <li>Type: {load.type}</li>
                            <li>ADR: {load.isAdr ? "Yes" : "No"}</li>
                            <li>Quantity: {load.quantity}</li>
                            <li>
                              Height:{" "}
                              {load.height !== undefined
                                ? load.height / 10
                                : undefined}{" "}
                              cm
                            </li>
                            <li>
                              Width:{" "}
                              {load.width !== undefined
                                ? load.width / 10
                                : undefined}{" "}
                              cm
                            </li>
                            <li>
                              Length:{" "}
                              {load.length !== undefined
                                ? load.length / 10
                                : undefined}{" "}
                              cm
                            </li>
                            <li>
                              Weight:{" "}
                              {load.weight !== undefined
                                ? load.weight / 1000
                                : undefined}{" "}
                              kg
                            </li>
                          </ul>
                        </>
                      ))}
                    </div>
                  )
                }
              >
                {loadsTotalVolume !== undefined && (
                  <Row align={"bottom"}>
                    <Col span={8}>
                      <Text type={"secondary"}>Volume: </Text>
                    </Col>
                    <Text type={"secondary"}>
                      {loadsTotalVolume} m<sup>3</sup>
                    </Text>
                  </Row>
                )}
                {loadsTotalWeight !== undefined && (
                  <Row align={"bottom"}>
                    <Col span={8}>
                      <Text type={"secondary"}>Weight: </Text>
                    </Col>
                    <Text type={"secondary"}>{loadsTotalWeight} kg</Text>
                  </Row>
                )}
              </Popover>
            );
          },
          width: "15%",
        },
        {
          title: "Pickup address",
          dataIndex: "pickupInformation",
          key: "pickupAddress",
          render: (pickupInformation: PickupDeliveryInformation) => (
            <Text type={"secondary"}>
              {!!pickupInformation?.location
                ? `${pickupInformation.location.postalCode}, ${pickupInformation.location.city} (${pickupInformation.location.state}), ${pickupInformation.location.country}`
                : ""}
            </Text>
          ),
          width: "17%",
        },
        {
          title: "Pickup time",
          dataIndex: "pickupInformation",
          key: "pickupTime",
          render: (pickupInformation: PickupDeliveryInformation) =>
            !!pickupInformation &&
            renderFromToTime(
              pickupInformation.timeFrom,
              pickupInformation.timeTo,
              pickupInformation.isTimeFlexible
            ),
          width: "17%",
        },
        {
          title: "Delivery address",
          dataIndex: "deliveryInformation",
          key: "deliveryAddress",
          render: (deliveryInformation: PickupDeliveryInformation) => (
            <Text type={"secondary"}>
              {!!deliveryInformation?.location
                ? `${deliveryInformation.location.postalCode}, ${deliveryInformation.location.city} (${deliveryInformation.location.state}), ${deliveryInformation.location.country}`
                : ""}
            </Text>
          ),
          width: "17%",
        },
        {
          title: "Delivery time",
          dataIndex: "deliveryInformation",
          key: "deliveryTime",
          render: (deliveryInformation: PickupDeliveryInformation) =>
            !!deliveryInformation &&
            renderFromToTime(
              deliveryInformation.timeFrom,
              deliveryInformation.timeTo,
              deliveryInformation.isTimeFlexible
            ),
          width: "17%",
        },
        {
          title: "Status",
          render: (order: Order) =>
            !!order.archived ? (
              <Tooltip title={"The order has been deleted by the carrier"}>
                <Text type={"danger"}>
                  <CloseOutlined />
                </Text>
              </Tooltip>
            ) : (
              <Text type={"secondary"}>-</Text>
            ),
          align: "center",
          width: "6%",
          fixed: "right",
        },
      ]}
      dataSource={ordersPaginated?.map((order) => ({
        ...order,
        key: order.id,
      }))}
      pagination={{
        defaultCurrent: 1,
        total: totalOrders,
        pageSize: perPage,
        onChange: (page, perPage) => {
          setPage(page);
          setPerPage(perPage);
        },
      }}
      loading={isLoadingOrdersPaginatedResponse}
      scroll={{ x: 1500 }}
    />
  );
};
