import {
  CreateOrderRequest,
  GetOrdersPaginatedRequest,
  OrdersPaginatedResponse,
  UpdateOrderRequest,
} from "DataInterfaces/Order";
import { useHttpGet } from "NetworkInterfaces/Core/HttpGet";
import {
  useMutation,
  useMutationOptions,
  useMutationReturn,
} from "NetworkInterfaces/Core/useMutation";
import client from "NetworkInterfaces/Core/ApiClient";

export const ORDERS_CACHE_KEY = "orders_cache_key";

export const useGetOrdersPaginated = (
  ordersPaginatedRequest: GetOrdersPaginatedRequest,
  enabled: boolean = true
): {
  ordersPaginatedResponse: OrdersPaginatedResponse | undefined;
  isLoadingOrdersPaginatedResponse: boolean;
} => {
  const {
    data: ordersPaginatedResponse,
    isLoading: isLoadingOrdersPaginatedResponse,
  } = useHttpGet(
    [
      ORDERS_CACHE_KEY,
      ordersPaginatedRequest.carrierId,
      ordersPaginatedRequest.page,
      ordersPaginatedRequest.perPage,
    ],
    "orders",
    {
      carrierId: ordersPaginatedRequest.carrierId,
      page: ordersPaginatedRequest.page,
      perPage: ordersPaginatedRequest.perPage,
    },
    {
      enabled: enabled,
    }
  );

  return {
    ordersPaginatedResponse: ordersPaginatedResponse,
    isLoadingOrdersPaginatedResponse: isLoadingOrdersPaginatedResponse,
  };
};

export const useCreateOrder = (
  options?: useMutationOptions<CreateOrderRequest>
): useMutationReturn<CreateOrderRequest> => {
  return useMutation(
    (payload) =>
      client.post({
        body: payload,
        url: `orders`,
      }),
    {
      invalidations: [
        {
          key: [ORDERS_CACHE_KEY],
        },
      ],
      ...options,
    }
  );
};

export const useUpdateOrder = (
  id?: string,
  options?: useMutationOptions<UpdateOrderRequest>
): useMutationReturn<UpdateOrderRequest> => {
  return useMutation(
    (payload) =>
      client.put({
        body: payload,
        url: `orders/${id}`,
      }),
    {
      invalidations: [
        {
          key: [ORDERS_CACHE_KEY, id],
        },
      ],
      ...options,
    }
  );
};

export const useDeleteOrder = (
  id?: string
): useMutationReturn<UpdateOrderRequest> => {
  return useMutation(
    (payload) =>
      client.delete({
        body: payload,
        url: `orders/${id}`,
      }),
    {
      invalidations: [
        {
          key: [ORDERS_CACHE_KEY, id],
        },
      ],
    }
  );
};
