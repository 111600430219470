import { hexToRgba } from "Utils/ColorConverter";
import { Layout, Menu, Typography } from "antd";
import { styled } from "styled-components";

const { Content, Header } = Layout;
const { Title } = Typography;

const TOP_HEADER_HEIGHT = "6vh";

export const TopHeader = styled(Header)`
  position: fixed;
  left: 0;
  top: 0;
  height: ${TOP_HEADER_HEIGHT} !important;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: space-between;

  .ant-typography {
    color: ${hexToRgba("#ffffff", 0.88)};
    margin: 0;
  }
`;

export const HeaderElement = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const HeaderText = styled(Title)``;

export const LowerContent = styled(Content)`
  position: fixed;
  top: 6vh;
  left: 0;
  width: 100%;
`;

export const MainSiderMenu = styled(Menu)`
  padding-bottom: 100vh;
`;

export const ContentContainer = styled(Content)`
  padding: 0 8px 0 16px;
  overflow-y: auto;
  height: calc(100vh - ${TOP_HEADER_HEIGHT} - 16px);
`;
