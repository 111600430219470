import { FunctionComponent, useState } from "react";
import { Avatar, Layout, Space, Spin, Typography } from "antd";
import {
  LoaderFunctionArgs,
  Outlet,
  redirect,
  useNavigate,
} from "react-router-dom";
import Cookies from "js-cookie";
import {
  ContentContainer,
  HeaderElement,
  HeaderText,
  LowerContent,
  MainSiderMenu,
  TopHeader,
} from "Components/BaseLayout/index.style";
import { useGetCurrentOperatorUser } from "NetworkInterfaces/OperatorUsers";
import { UserOutlined } from "@ant-design/icons";
import { MainSections } from "Constants/MainSections";

const { Text } = Typography;
const { Sider } = Layout;

const MAIN_SECTIONS_PROPS: {
  [key in MainSections]: {
    label: JSX.Element;
    key: string;
    target: string;
  };
} = {
  [MainSections.Carriers]: {
    label: (
      <>
        <UserOutlined />
        <Text>Carriers</Text>
      </>
    ),
    key: MainSections.Carriers,
    target: "/carriers",
  },
};

export const baseLayoutLoader = ({ request }: LoaderFunctionArgs) => {
  const accessToken = Cookies.get("access_token");

  if (!accessToken) {
    let params = new URLSearchParams();
    if (params.size > 0) {
      params.set("from", new URL(request.url).pathname);
      return redirect("/auth?" + params.toString());
    } else {
      return redirect("/auth");
    }
  }
  return null;
};

export const BaseLayout: FunctionComponent = (props) => {
  const { operatorUser, isLoadingOperatorUser } = useGetCurrentOperatorUser();
  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(false);

  return (
    <Spin spinning={isLoadingOperatorUser}>
      <Layout className="layout">
        <TopHeader>
          <HeaderElement>
            <img
              src={require("assets/images/javis_logo_white.png")}
              style={{
                maxHeight: "60%",
                width: "auto",
                maxWidth: "100%",
                paddingRight: "10px",
                marginBottom: "5px",
              }}
              alt="White Javis Logo"
            />
            <HeaderText level={5}>• Operators</HeaderText>
          </HeaderElement>
          <Space direction={"horizontal"} size={12}>
            <HeaderText level={5}>{operatorUser?.firstName}</HeaderText>
            <Avatar
              src={
                !!operatorUser ? (
                  operatorUser.profilePictureUrl
                ) : (
                  <UserOutlined />
                )
              }
            />
          </Space>
        </TopHeader>
        <LowerContent>
          <Layout>
            <Sider
              collapsible
              collapsed={collapsed}
              theme={"dark"}
              onCollapse={(value) => setCollapsed(value)}
            >
              <MainSiderMenu
                theme={"dark"}
                defaultSelectedKeys={["1"]}
                mode="inline"
                onClick={(e) => {
                  navigate(MAIN_SECTIONS_PROPS[e.key as MainSections].target);
                }}
                items={Object.values(MAIN_SECTIONS_PROPS)}
              />
            </Sider>
            <ContentContainer>
              <Outlet />
            </ContentContainer>
          </Layout>
        </LowerContent>
      </Layout>
    </Spin>
  );
};
