import { OperatorUser } from "DataInterfaces/OperatorUser";
import { useHttpGet } from "NetworkInterfaces/Core/HttpGet";

export const OPERATOR_USER_CACHE_KEY = "operator_user_cache_key";

export const useGetCurrentOperatorUser = (): {
  operatorUser: OperatorUser | undefined;
  isLoadingOperatorUser: boolean;
} => {
  const { data: operatorUser, isLoading: isLoadingOperatorUser } = useHttpGet(
    [OPERATOR_USER_CACHE_KEY],
    "operators/current"
  );

  return {
    operatorUser: operatorUser,
    isLoadingOperatorUser: isLoadingOperatorUser,
  };
};
