import { useMatchMutate } from "NetworkInterfaces/Core/useMatchMutate";
import { Key } from "swr";

export interface Invalidation {
  key: Key;
  transform?: (operationResult: any, completeData: any) => any;
}

export interface useInvalidateReturn {
  invalidate: (operationResult: any, invalidations?: Invalidation[]) => void;
}

export const useInvalidate = (): useInvalidateReturn => {
  const { matchMutate } = useMatchMutate();

  return {
    invalidate: async (
      operationResult: any,
      invalidations?: Invalidation[]
    ) => {
      for (const invalidation of invalidations || []) {
        const mutator =
          operationResult !== undefined && invalidation.transform !== undefined
            ? async (completeData: any) => {
                return (
                  invalidation.transform !== undefined &&
                  invalidation.transform(operationResult, completeData)
                );
              }
            : undefined;

        if (mutator) {
          await matchMutate(invalidation.key, mutator, {
            revalidate: false,
          });
        } else {
          await matchMutate(invalidation.key);
        }
      }
    },
  };
};
