import { AuthenticationContainer } from "UI/Authentication/index.style";
import { hexToRgba } from "Utils/ColorConverter";
import { Button, Card, Col, Form, Input, Row, Typography } from "antd";
import Cookies from "js-cookie";
import { FunctionComponent, useEffect, useState } from "react";
import { redirect } from "react-router-dom";

const columnContentStyle = {
  height: "100vh",
  display: "flex",
  alignItems: "center",
};

type FieldType = {
  username?: string;
  password?: string;
};

export const authenticationPageLoader = () => {
  const accessToken = Cookies.get("access_token");
  if (!!accessToken) {
    return redirect("/");
  }
  return null;
};

export const AuthenticationPage: FunctionComponent = () => {
  const [responseStatus, setResponseStatus] = useState<number | undefined>();

  const onSubmit = (values: FieldType) => {
    const completeUrl = new URL(`${process.env.REACT_APP_BACKEND_URL}/auth`);
    const response = fetch(completeUrl.toString(), {
      credentials: "include",
      body: JSON.stringify({
        username: values.username,
        password: values.password,
      }),
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
    });
    response
      .then((res) => res.status)
      .then((status) => {
        setResponseStatus(status);
      });
  };
  const onSubmitFailed = (errorInfo: any) => {};

  useEffect(() => {
    if (responseStatus === 200) {
      window.location.href = "/";
    }
  }, [responseStatus]);

  return (
    <AuthenticationContainer>
      <Row style={{ width: "100%" }}>
        <Col span={3} />
        <Col span={10}>
          <div style={{ ...columnContentStyle, justifyContent: "flex-end" }}>
            <img
              src={require("assets/images/javis_logo_with_tagline.png")}
              style={{
                maxHeight: "15%",
                width: "auto",
                maxWidth: "100%",
              }}
              alt="Javis Logo and tagline 'A Faster and More Efficient Supply Chain'"
            />
          </div>
        </Col>
        <Col span={8}>
          <div style={{ ...columnContentStyle, width: "100%" }}>
            <Card
              title="Sign in"
              bordered={false}
              style={{ width: 300, background: hexToRgba("#7592F9", 0.25) }}
            >
              <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                initialValues={{ remember: true }}
                onFinish={onSubmit}
                onFinishFailed={onSubmitFailed}
                autoComplete="off"
              >
                <Form.Item<FieldType>
                  label="Username"
                  name="username"
                  rules={[
                    { required: true, message: "Please input your username!" },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item<FieldType>
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                {responseStatus !== 200 && responseStatus !== undefined && (
                  <Typography.Text type="danger">
                    Username or password is incorrect.
                  </Typography.Text>
                )}

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </div>
        </Col>
        <Col span={3} />
      </Row>
    </AuthenticationContainer>
  );
};
