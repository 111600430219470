import {
  Invalidation,
  useInvalidate,
} from "NetworkInterfaces/Core/useInvalidate";
import { useState } from "react";

export interface MutationError {
  errorCode: string;
  errorDescription: string;
}

type useMutationCallback<Data = any, Return = void> = (
  data: Data
) => Promise<Return>;

export interface useMutationOptions<Data = any, Return = void> {
  onError?: (error: MutationError) => void;
  onSuccess?: (response?: Return, request?: Data) => void;
  invalidations?: Invalidation[];
}

export interface useMutationReturn<Data = void, Return = void> {
  mutate: (data: Data) => void;
  isLoading: boolean;
  data?: Return;
}

export const isMutationError = (error: any): error is MutationError => {
  return error.errorCode !== undefined && error.errorDescription !== undefined;
};

export const useMutation = <Data = void, Return = void>(
  mutationFn: useMutationCallback<Data, Return>,
  options?: useMutationOptions<Data, Return>
): useMutationReturn<Data, Return> => {
  const { invalidate } = useInvalidate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<Return | undefined>(undefined);

  const onError = (error: MutationError) => {
    options?.onError && options.onError(error);
  };

  const onFinally = () => {
    setIsLoading(false);
  };

  return {
    mutate: (data) => {
      setIsLoading(true);

      mutationFn(data)
        .then((response) => {
          setResponse(response);
          options?.onSuccess && options.onSuccess(response, data);

          if (options?.invalidations) {
            invalidate(response, options.invalidations);
          }
        })
        .catch(onError)
        .finally(onFinally);
    },
    isLoading,
    data: response,
  };
};
