export const hexToRgba = (hex: string, alpha: number): string => {
  // Check if the alpha value is within the valid range [0, 1]
  if (alpha < 0 || alpha > 1) {
    throw new Error("Alpha value should be between 0 and 1");
  }

  // Check if the input hex color is valid
  const hexRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
  const result = hexRegex.exec(hex);

  if (!result) {
    throw new Error("Invalid HEX color format");
  }

  // Convert hex values to decimal
  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);

  // Construct the RGBA color string
  const rgbaColor = `rgba(${r},${g},${b},${alpha})`;
  return rgbaColor;
};
